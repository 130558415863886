import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
	selector: '[gridShortcuts]',
	standalone: true,
})
export class GridShortcutsDirective implements OnInit {
	constructor(private el: ElementRef) {}

	ngOnInit(): void {
		if (this.el) {
			this.el.nativeElement.classList.add('grid');
			if (this.el?.nativeElement?.children.length && this.el?.nativeElement?.children.length > 1) {
				this.el.nativeElement.classList.add('grid-e2-sm');
			}
		}
	}
}
