import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
	selector: '[autocompleteOff]',
	standalone: true,
})
export class AutocompleteOffDirective implements AfterViewInit {
	constructor(private el: ElementRef) {}

	ngAfterViewInit(): void {
		const inputs = this.el.nativeElement.querySelectorAll('input');

		inputs.forEach((input: HTMLInputElement) => {
			input.setAttribute('autocomplete', 'off');
		});
	}
}
